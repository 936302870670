/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, CSSProperties, useEffect } from "react";
import PropTypes from "prop-types";
import StationEvents from "./stationEvents";
import SearchBar from "./searchBar";
import { Modal, Button } from "react-bootstrap";
import BounceLoader from "react-spinners/ClipLoader";
import axios from 'axios';

const executeSearch = (value, data, isMaster = false, exact = true) => {
  let statistics = Object.assign({}, data);

  const bibSearch = (isresolved, bibNumbers, station, groupToSearch, searchValue, isMaster = false, index = null, exact = true) => {
    let results;
    if(isresolved){
      results = [];
      if (!isMaster) {
        statistics[station][groupToSearch].forEach((runner) => {
          const valueToSearch = exact ? 
          (`${runner.first_name?.toLowerCase()},${runner.last_name?.toLowerCase()},${runner.bib_number?.toLowerCase()}`).split(',') : 
          runner.first_name?.toLowerCase() + runner.last_name?.toLowerCase() + runner.bib_number?.toLowerCase();
            
          if (valueToSearch.includes(searchValue.toLowerCase())) {
            results.push(runner);
          }
        });
      } else {
        statistics[index][station][groupToSearch].forEach((runner) => {
          const valueToSearch = exact ? (`${runner.first_name?.toLowerCase()},${runner.last_name?.toLowerCase()},${runner.bib_number?.toLowerCase()}`).split(',') :
           runner.first_name?.toLowerCase() + runner.last_name?.toLowerCase() + runner.bib_number?.toLowerCase();
          if (valueToSearch.includes(searchValue.toLowerCase())) {
            results.push(runner);
          }
        });
      }
    }else{
      results = {};
      bibNumbers.forEach((bibNumber) => {
        if (!isMaster) {
          const runner = statistics[station][groupToSearch][bibNumber];
          const valueToSearch = exact ? (`${runner.first_name?.toLowerCase()},${runner.last_name?.toLowerCase()},${runner.bib_number?.toLowerCase()}`).split(',') : 
          runner.first_name?.toLowerCase() + runner.last_name?.toLowerCase() + runner.bib_number?.toLowerCase();
          if (valueToSearch.includes(searchValue.toLowerCase())) {
            results[bibNumber] = statistics[station][groupToSearch][bibNumber];
          }
        } else {
          const runner = statistics[index][station][groupToSearch][bibNumber];
          const valueToSearch = exact ? (`${runner.first_name?.toLowerCase()},${runner.last_name?.toLowerCase()},${runner.bib_number?.toLowerCase()}`).split(',') : 
          runner.first_name?.toLowerCase() + runner.last_name?.toLowerCase() + runner.bib_number?.toLowerCase();
          if (valueToSearch.includes(searchValue.toLowerCase())) {
            results[bibNumber] = statistics[index][station][groupToSearch][bibNumber];
          }
        }
      });
    }

    if (!isMaster) {
      statistics[station][groupToSearch] = results;
    } else {
      Object.keys(statistics).forEach((event) => {
        statistics[index][station][groupToSearch] = results;
      });
    }
  };

  const getStatistics = (statistics) => {
    if (isMaster) {
      Object.keys(statistics).forEach((event, index) => {
        Object.keys(statistics[event]).forEach((station) => {
          const activeBibNumbers = Object.keys(statistics[event][station].active_runners);
          const resolvedBibNumbers = Object.keys(
            statistics[event][station].resolved_runners
          );

          bibSearch(false, activeBibNumbers, station, "active_runners", value, isMaster, index);
          bibSearch(true, resolvedBibNumbers, station, "resolved_runners_list", value, isMaster, index);
        });
      });
    } else {
      Object.keys(statistics).forEach((station) => {
        const activeBibNumbers = Object.keys(statistics[station].active_runners);
        const resolvedBibNumbers = Object.keys(
          statistics[station].resolved_runners
        );

        bibSearch(false, activeBibNumbers, station, "active_runners", value);
        bibSearch(true, resolvedBibNumbers, station, "resolved_runners_list", value);
      });
    }
  }

  getStatistics(statistics);

  return statistics;
};

const searchLatestEventTriageId = (statistics, isMaster) => {
  let eventTriage = {eventId: -1, triageId: -1};
  if (isMaster) {
    Object.keys(statistics).forEach((data) => {
          Object.keys(statistics[data]).forEach((station) => {
              const lastEventId = statistics[data][station].last_event_id;
              const lastTriageId = statistics[data][station].last_triage_id;

              if (parseInt(lastTriageId) > eventTriage.triageId) {
                eventTriage.triageId = parseInt(lastTriageId);
              }
              if (parseInt(lastEventId) > eventTriage.eventId) {
                eventTriage.eventId = parseInt(lastEventId);
              }
              
          });
      });
  } else {
      Object.keys(statistics).forEach((station) => {

          const lastEventId = statistics[station].last_event_id;
          const lastTriageId = statistics[station].last_triage_id;

          if (parseInt(lastTriageId) > eventTriage.triageId) {
            eventTriage.triageId = parseInt(lastTriageId);
          }
          if (parseInt(lastEventId) > eventTriage.eventId) {
            eventTriage.eventId = parseInt(lastEventId);
          }
      });
  }
  
  return eventTriage;
};

const searchLatestEventId = (statistics, isMaster) => {
  let eventId = -1;
  if (isMaster) {
    Object.keys(statistics).forEach((data) => {
          Object.keys(statistics[data]).forEach((station) => {
              const activeBibNumbers = statistics[data][station].active_runners;
              const resolvedBibNumbers = statistics[data][station].resolved_runners_list;

              for (let active in activeBibNumbers) {
                  if (parseInt(activeBibNumbers[active].race_event_id) > eventId) {
                      eventId = parseInt(activeBibNumbers[active].race_event_id);
                  }
              }

              for (let resolved in resolvedBibNumbers) {
                  if (parseInt(resolvedBibNumbers[resolved].race_event_id) > eventId) {
                      eventId = parseInt(resolvedBibNumbers[resolved].race_event_id);
                  }
              }
          });
      });
  } else {
      Object.keys(statistics).forEach((station) => {
          const activeBibNumbers = statistics[station].active_runners;
          const resolvedBibNumbers = statistics[station].resolved_runners_list;

          for (let active in activeBibNumbers) {
              if (parseInt(activeBibNumbers[active].race_event_id) > eventId) {
                  eventId = parseInt(activeBibNumbers[active].race_event_id);
              }
          }

          for (let resolved in resolvedBibNumbers) {
              if (parseInt(resolvedBibNumbers[resolved].race_event_id) > eventId) {
                  eventId = parseInt(resolvedBibNumbers[resolved].race_event_id);
              }
          }
      });
  }

  return eventId;
};

// interval react hook, to be moved to utils file
function useInterval(callback, delay) {
  const intervalRef = React.useRef(null);
  const savedCallback = React.useRef(callback);
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  React.useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === 'number') {
      intervalRef.current = window.setInterval(tick, delay);
      return () => window.clearInterval(intervalRef.current);
    }
  }, [delay]);
  return intervalRef;
}

export const TriagePage = () => {
  const [title, setTitle] = useState('');
  const [isMaster, setIsMaster] = useState(false);
  const [stations, setStations] = useState([]);
  const [statistics, setStatistics] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [loadingStation, setIsLoadingStation] = useState(null);
  const [statusUpdateTime, setStatusUpdateTime] = useState("loading");

  useEffect(() => {
    axios.get(`${window.location.origin}/triage-data-load/${window.location.pathname.replace('/command-center/', '').split('/triage-data')[0].replace('/index.php', '')}`)
    .then((response) => {
      setTitle(response.data.raceTitle);
      setIsMaster(response.data.isMaster);
      setStations(JSON.parse(response.data.stations));
      setStatusUpdateTime(response.data.last_status_update);
      setIsLoading(false);
    })
    .catch(error => console.error('axios', error));
  }, []);

  const [latestEventTriage, setLatestEventTriage] = useState({eventId: -1, triageId: -1});
  const [latestEventId, setLatestEventId] = useState();
  const [showNotFound, setShowNotFound] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (loadingStation) {
      loadData(loadingStation);
    }
  }, [loadingStation]);

  const loadData = (stationId) => {
    setIsLoadingStation(stationId);

    axios.get(`${window.location.origin}/triage-data-api/${window.location.pathname.replace('/command-center/', '').split('/triage-data')[0].replace('/index.php', '')}?station_id=${stationId}`)
      .then((response) => {
          setTitle(response.data.raceTitle);
          setIsMaster(response.data.isMaster);
          setStations(JSON.parse(response.data.stations));
          setStatistics(JSON.parse(response.data.statistics));
      })
      .catch(error => console.error('axios', error));
  };

  const handleStatusUpdate = () => {
    setIsLoading(true);

    axios.get(`${window.location.origin}/triage-recalculate-data-api?race_id=${window.location.pathname.replace('/command-center/', '').split('/triage-data')[0].replace('/index.php', '')}`)
        .then((response) => {
          window.location.reload();
        })
        .catch(error => setIsLoading(false));
  }

  const handleSearch = (value) => {
    setIsLoading(true);
    setSearchValue(value);
    //const results = executeSearch(value, props.statistics, props.isMaster);    
    axios.get(`${window.location.origin}/triage-data-api/${window.location.pathname.replace('/command-center/', '').split('/triage-data')[0].replace('/index.php', '')}${!value ? '' : '?search='+value}`)
        .then((response) => {
            setTitle(response.data.raceTitle);
            setIsMaster(response.data.isMaster);
            setStations(JSON.parse(response.data.stations));
            const eventId = searchLatestEventId(JSON.parse(response.data.statistics), response.data.isMaster);
            const eventTriage = searchLatestEventTriageId(JSON.parse(response.data.statistics), response.data.isMaster);
            setLatestEventId(eventId);
            setLatestEventTriage(eventTriage);
            setIsSearching(true);
            setStatistics(JSON.parse(response.data.statistics));
            if(eventId === -1 && value){
              //show modal  search '888' not found
              setShowNotFound(true);
            }

            if (!value) {
              setIsSearching(false);
            }

            setIsLoading(false);
        })
        .catch(error => console.error('axios', error));
    //setSearchResults(results);
  };

  const [isSearching, setIsSearching] = useState(false);
  const [autoRefresh, setautoRefresh] = useState(true);

  useInterval(
    () => {
      window.location.reload();
      setIsSearching(true);
    },
    autoRefresh ? 100000 : null
  );
  const toggle = () => {
    setautoRefresh(!autoRefresh);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-7">
          <h1>{title}</h1>
        </div>

        <div className="col-xs-5 " style={{height: 80}}>
          
          <div class="row">
              <div class="col-xs-12 text-right" style={{marginTop: 25}}>
                <button id="btn-auto-refresh" style={{marginRight: 4}} onClick={()=>{toggle()}} className="btn btn-link" >Auto Refresh is {autoRefresh ? 'ON' : 'OFF'}</button>
                <small id="statusUpdate.dt " style={{marginRight: 2}}>Last status update {statusUpdateTime}</small>
                <button id="updateStatusBtn" onClick={()=>{handleStatusUpdate()}} type="button" class="btn btn-sm btn-secondary">Update Status</button>
              </div>
          </div>
        </div>
    </div>
      <br />
      <SearchBar onChange={handleSearch} />
      
      {
        isLoading ? (<div className="row">
          <div className="col-xs-5 " ></div>
          <div className="col-xs-5 " ><BounceLoader
          color={'#9da4cf'}
          loading={isLoading}
          size={70}
          aria-label="Loading Spinner"
          data-testid="loader"
        /></div>
          
        <div className="col-xs-2 " ></div>
        </div>) : 
        (<StationEvents setIsLoadingStation={setIsLoadingStation} loadingStation={loadingStation} latestEventId={latestEventId} latestEventTriageId={latestEventTriage} isSearching={isSearching} setIsSearching={setIsSearching} isMaster={isMaster} stations={stations} statistics={statistics} />)
      }
      

      

      <Modal show={showNotFound} onHide={()=>setShowNotFound(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Search Not Found</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Exact search for '{searchValue}'' not found. You can refresh the page if urgent or come back in 2 minutes.</h4>
            
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={()=>setShowNotFound(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
    
    </div>
  );
};

TriagePage.propTypes = {
  title: PropTypes.string,
  stations: PropTypes.array,
  statistics: PropTypes.object,
};

TriagePage.defaultProps = {
  title: "",
  stations: [],
  statistics: {},
};
