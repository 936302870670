import React, { useEffect, useState } from "react";
import { Panel, Table } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import '../../../../App.css';

const StationEvents = ({ setIsLoadingStation, loadingStation, latestEventId, latestEventTriageId, isSearching, setIsSearching, isMaster, stations, statistics }) => {
  if (isMaster) {
    return (
      <>
        {
          stations.map((childRace, index) => {
            return loadEventStation(setIsLoadingStation, loadingStation, latestEventId, latestEventTriageId, childRace['data'], statistics[index], isSearching, true, childRace['name']);
          })
        }
      </>
    )
  } else {
    return loadEventStation(setIsLoadingStation, loadingStation, latestEventId, latestEventTriageId, stations, statistics, isSearching);
  }
};

const loadEventStation = (setIsLoadingStation, loadingStation, latestEventId, latestEventTriageId, stations, statistics, isSearching = false, isMaster = false, raceName = null) => {
  return (
    <>
      {isMaster &&
        <h3>{raceName}</h3>
      }
      {stations.map((station) => {
        const panelId = `station-event-${station.id}`;
        return (
          <>
            <Station
              key={panelId}
              index={panelId}
              station={station}
              statistics={statistics ? statistics[station.name] : []}
              isMaster={isMaster}
              isSearching={isSearching}
              latestEventId={latestEventId}
              latestEventTriageId={latestEventTriageId}
              setIsLoadingStation={setIsLoadingStation}
              isLoadingStation={loadingStation}
            />
          </>
        );
      })}
    </>
  );
}

const SubStation = ({ index, station, statistics, latestEventId, latestEventTriageId}) => {
  const subStyle = { "backgroundColor": "#D3D3D3" };
  const [expandPanel, setExpandPanel] = useState(false)
  const activeBibNumbers =  Object.keys(statistics?.active_runners ?? []);
  const resolvedBibNumbers = Object.keys(statistics?.resolved_runners ?? []);

  const activeBibLength = Object.entries(statistics?.active_runners ?? {})?.filter(item => item[1]?.sub_activity === station.id).length
  const resolvedBibLength = Object.entries(statistics?.resolved_runners ?? {})?.filter(item => item[1]?.sub_activity === station.id).length

  const fixedStationName = (name) => name.length > 30 ? (name.substr(0,30) + '...') : name

  useEffect(()=>{
    if(activeBibLength > 0 || resolvedBibLength > 0){
      setExpandPanel(true)
    }else{
      setExpandPanel(false)
    }
  },[statistics])
  return (
    <Panel  defaultExpanded={activeBibLength > 0 || resolvedBibLength > 0} expanded={expandPanel} key={index} eventKey={index} className="see-data-panel">
      <Panel.Heading className="see-data-panel-heading subactivity" >
        <Panel.Title  onClick={()=>setExpandPanel(!expandPanel)} toggle>
          
            {station.name ? <span style={{marginLeft: 20}} title={station.name}>{fixedStationName(station.name)}</span> : <span>&nbsp;</span>}
            <StationSummary statistics={statistics?.sub?.[station.name]} />
          
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body
        collapsible
        style={{ padding: 0 }}
        className="see-data-panel-body"
      >
        <EventTable
          title="ACTIVE"
          bibNumbers={activeBibNumbers}
          subActivityId = {station.id}
          events={statistics?.active_runners}
          latestEventTriageId={latestEventTriageId}
          latestEventId={latestEventId}
        />

        <EventTable
          title="RESOLVED"
          bibNumbers={resolvedBibNumbers}
          subActivityId = {station.id}
          events={statistics?.resolved_runners}
          latestEventTriageId={latestEventTriageId}
          latestEventId={latestEventId}
        />

      </Panel.Body>
    </Panel>
  );
};

const Station = ({ setIsLoadingStation, isLoadingStation, latestEventId, latestEventTriageId, index, station, statistics, isMaster = false, isSearching = false }) => {
  const [expandPanel, setExpandPanel] = useState(false)
  const activeBibNumbers =  Object.keys(statistics?.active_runners ?? []);
  const resolvedBibNumbers = Object.keys(statistics?.resolved_runners  ?? []);
  const fixedStationName = (name) => name.length > 30 ? (name.substr(0,30) + '...') : name

  useEffect(()=>{
    if(isLoadingStation == station?.id || (isSearching) && (activeBibNumbers.length > 0 || statistics?.resolved_runners_list?.length > 0)/* && (!isMaster || isSearching)*/){
      setExpandPanel(true);
      setIsLoadingStation(null);
    }else{
      setExpandPanel(false);
    }
  },[statistics])

  const onStationPress = () => {
    if(!expandPanel) {
      setIsLoadingStation(station?.id);
    } else {
      setExpandPanel(false);
    }
  };

  return (
    <Panel defaultExpanded={(isSearching) && (activeBibNumbers.length > 0 || statistics?.resolved_runners_list?.length > 0)/* && (!isMaster || isSearching)*/} expanded={expandPanel} key={index} eventKey={index} className="see-data-panel">
      <Panel.Heading className="see-data-panel-heading activity">
        <Panel.Title onClick={onStationPress} toggle>
          {isLoadingStation == station?.id && <i class="fa fa-spinner fa-spin"></i>}
          {isLoadingStation != station?.id &&
            <>
              {station.name ? <span title={station.name}>{fixedStationName(station.name)}</span> : <span>&nbsp;</span>}
              <StationSummary station={station} statistics={statistics} />
            </>
          }
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body
        collapsible
        style={{ padding: 0 }}
        className="see-data-panel-body"
      >

      <EventTable
        title="ACTIVE"
        bibNumbers={activeBibNumbers}
        events={statistics?.active_runners}
        subActivityId = {'0'}
        latestEventTriageId={latestEventTriageId}
        latestEventId={latestEventId}
      />

      <EventTable
        title="RESOLVED"
        bibNumbers={resolvedBibNumbers}
        events={statistics?.resolved_runners}
        subActivityId = {'0'}
        latestEventTriageId={latestEventTriageId}
        latestEventId={latestEventId}
      />

        {station.sub_activities?.map?.((sub_station) => {
          const panelId = `sub-station-event-${sub_station?.id}`;
          return (
            <>
            <SubStation
              key={panelId}
              index={panelId}
              station={sub_station}
              statistics={statistics}
              latestEventTriageId={latestEventTriageId}
              latestEventId={latestEventId}
            />
            </>
          );
        })}

      </Panel.Body>
    </Panel>
  );
};

const StationSummary = ({ station, statistics }) => {
  const countStyle = { "fontSize": 15 };

  return (
    <span className="pull-right">
      <span style={countStyle}>
        <strong className='summary-label'>TOTAL {(statistics?.active ?? (station?.counts?.active ?? 0)) + (statistics?.resolved ?? (station?.counts?.resolved ?? 0)) + (statistics?.minor_assistance_count ?? (station?.counts?.minor ?? 0))}</strong>
      </span>
      <span><strong className='summary-label'> | </strong></span>
      <span style={countStyle}>
        <strong className='summary-label'>ACTIVE {statistics?.active ?? (station?.counts?.active ?? 0)}</strong>
      </span>
      &nbsp;
      <span className="badge progress-bar-danger" style={countStyle}>
        <span className="hidden-xs hidden-sm hidden-md"></span> {statistics?.red?.count ?? (station?.counts?.active_red ?? 0)}
      </span>
      &nbsp;
      <span className="badge progress-bar-warning" style={countStyle}>
      <span className="hidden-xs hidden-sm hidden-md"></span> {statistics?.yellow?.count ?? (station?.counts?.active_yellow ?? 0)}
      </span>
      &nbsp;
      <span className="badge progress-bar-success" style={countStyle}>
      <span className="hidden-xs hidden-sm hidden-md"></span> {statistics?.green?.count ?? (station?.counts?.active_green ?? 0)}
      </span>
      &nbsp;
      <span className="badge progress-bar-gray" style={countStyle,{"color":  'black'}}>
      <span className="hidden-xs hidden-sm hidden-md"></span> {statistics?.gray?.count ?? (station?.counts?.active_gray ?? 0)}
      </span>
      <span><strong className='summary-label'> | </strong></span>
      &nbsp;
      <span style={countStyle}>
      <strong className='summary-label'> MINOR {statistics?.minor_assistance_count ?? (station?.counts?.minor ?? 0)}</strong>
      </span>
      <span><strong className='summary-label'> | </strong></span>
      &nbsp;
      <span style={countStyle}>
      <strong className='summary-label'> RESOLVED {statistics?.resolved ?? (station?.counts?.resolved ?? 0)}</strong>
      </span>
      &nbsp;
      <span style={countStyle}>
      <strong className='summary-label'> TRANSPORT {statistics?.transport ?? (station?.counts?.transport ?? 0)}</strong>
      </span>
    </span>
  );
};

const EventTable = ({ title, bibNumbers, events, subActivityId, latestEventId, latestEventTriageId }) => {
  const [expandPanel, setExpandPanel] = useState(false)
  useEffect(()=>{
    if(bibNumbers.length > 0){
      setExpandPanel(true)
    }else{
      setExpandPanel(false)
    }
  },[bibNumbers])
  return (
    <Panel defaultExpanded={bibNumbers.length > 0} expanded={expandPanel} className="see-data-panel">
      <Panel.Heading className="see-data-panel-heading-2 regularpanel">
        <Panel.Title onClick={()=>setExpandPanel(!expandPanel)} toggle><strong>{title}</strong></Panel.Title>
      </Panel.Heading>
      <Panel.Body
        collapsible
        style={{ padding: 0 }}
        className="see-data-panel-body"
      >
        <Table responsive striped bordered condensed hover style={{marginBottom:'0'}}>
          <thead>
            <tr>
              <td>Status</td>
              <td>{isResolved(title) ? 'Checked Out Time' : 'Checked In Time'}</td>
              <td>ID Number</td>
              <td>First Name</td>
              <td>Last Name</td>
              <td>Age</td>
              <td>Provider Info</td>
              <td>Primary Contact</td>
              {isResolved(title)&&<td>Note</td>}
              {isResolved(title)&&<td>Hospital </td>}
            </tr>
          </thead>
          <tbody>
            {/* {isResolved(title) ?
            events?.map((eventItem, index) => {
              if(subActivityId && subActivityId == eventItem.sub_activity){
                return <RowEvent isresolve={isResolved(title)} key={index} index={index} event={eventItem} isLatest={parseInt(eventItem.race_event_id) === latestEventId} />
              }
            })
            :
            bibNumbers?.map((bibNumber, index) => {
              if(subActivityId && subActivityId == events[bibNumber].sub_activity){
                return <RowEvent isresolve={isResolved(title)} key={index} index={index} event={events[bibNumber]} isLatest={parseInt(events[bibNumber].race_event_id) === latestEventId}/>
              }
            })
          } */}
          {
            bibNumbers?.map((bibNumber, index) => {
              if(subActivityId && subActivityId == events[bibNumber].sub_activity){
                return <RowEvent isresolve={isResolved(title)} key={index} index={index} event={events[bibNumber]} isLatest={parseInt(events[bibNumber].triage_id) === latestEventTriageId?.triageId || parseInt(events[bibNumber].race_event_id) === latestEventId }/>
              }
            })
          }
          </tbody>
        </Table>
      </Panel.Body>
    </Panel>
  );
};

const RowEvent = ({ index, event, isresolve, isLatest }) => {
  const age = calculateAge(event?.birth_date)
  const contact = formatContact(event ?? {primary_contact_phone:undefined, primary_contact_first_name:undefined})

  let startTime = 'Not Done';

  if (event?.has_checkout || event?.has_checkin || event?.is_spot || event?.is_disposition) {
    startTime = formatTimeInitiated(event ?? {time_initiated: undefined})
  }

  // Adds another * for sagbus / mobile activity
  if (event?.is_mobile_activity && event?.is_checkin) {
    startTime = `${startTime}*`;
  }else if(event?.is_mobile_activity && event?.is_checkout){
    startTime = `${startTime}**`;
  }else if(!event?.has_checkin) {
    startTime = `${startTime} *`;
  }else if (!event?.has_checkout && event?.is_checkout){
    startTime = `NONE ${startTime}*`;
  }

  return (
    <tr key={index} style={isLatest ? {background: 'yellow'} : {}}>
      <td>
        <RowEventStatus status={formatStatus(isresolve,event?.triage_status,event?.protocol, event?.status_name)} />
      </td>
      <td>{startTime}</td>
      <td>{event?.bib_number}</td>
      <td>{event?.first_name}</td>
      <td>{event?.last_name}</td>
      <td>{age === '' ? 'unknown' : (`${age}, ${event?.gender}`)}</td>
      <td>{event?.provider_info.trim() ==='' ? (event?.reporter_info ? event?.reporter_info: 'Not stated' ) : event?.provider_info}</td>
      <td>{contact}</td>
      {isresolve && <td>{event?.notes}</td>}
      {(isresolve && (event?.hospital || event?.triage_status === "50"))
      &&
      <td>
        {<img data-tip={event?.hospital ?? 'Unreported'} data-for={`tooltip_${index}`} width={20} height={20} src="https://img.icons8.com/ios-filled/100/4a90e2/hospital-2.png"/>}
        <p>{event?.hospital ?? 'Unreported'}</p>
      </td>
      }
      <ReactTooltip
            id={`tooltip_${index}`}
            multiline={true}
          />
    </tr>
  );
};

const RowEventStatus = ({ status }) => {
  return (
    <span className={`badge progress-bar-${status.colorLevel}`}>
      {status.levelName}
    </span>
  );
};

function calculateAge (dateString) {
  if (!dateString)
      return ''
  return (new Date().getFullYear() - dateString.substring(0, 4))
}

function formatContact ({primary_contact_phone, primary_contact_first_name}) {
  if (!primary_contact_phone)
      return 'None available'
  return `${primary_contact_first_name} (${primary_contact_phone})`
}

function formatTimeInitiated ({time_initiated}) {
  return time_initiated ? time_initiated.substring(5, 16) : ''
}

function isResolved (title) {
  return title === "RESOLVED"
}

function formatStatus(isresolve,status,protocol,status_name) {
  let colorLevel = ''
  let levelName = status_name ?? 'None'
  let charCode = 'A'

  if(levelName.toLowerCase() === 'minor' && status === 'blue'){
    colorLevel = 'blue'
    charCode = 'B'
    return { id: status, colorLevel, levelName, charCode }
  }
  if (status) {
    if (status === 'delayed') {
      colorLevel = 'warning'
      charCode = 'D'
    } else if (status === 'immediate') {
      colorLevel = 'danger'
      charCode = 'I'
    } else if (status === 'minor') {
      colorLevel = 'success'
      charCode = 'M'
    }
  } else {// this is to be removed as we have moved from using  protocols to decide color to using the status name except for old data which needs this code.
    if(isresolve){
      if (protocol < 30) {
        colorLevel = 'success'
        charCode = 'M'
      }

      if (protocol >= 30 && protocol < 40) {
        colorLevel = 'warning'
        charCode = 'D'
      }

      if (protocol >= 40) {
        colorLevel = 'danger'
        charCode = 'I'
      }
    }else{
      if (protocol < 15) {
        charCode = 'M'
      }

      if (protocol >= 15 && protocol < 35) {
        colorLevel = 'warning'
        charCode = 'D'
      }

      if (protocol >= 35) {
        colorLevel = 'danger'
        charCode = 'I'
      }
    }
  }


  return { id: status, colorLevel, levelName, charCode }
}

export default StationEvents;
