import { Suspense } from "react";
import TriageComponent from "./app/command-center/components/triagePage";

function App() {
  return (
    <div className="App">
      <Suspense fallback={<h1>Loading status...</h1>}>
        <TriageComponent/>
      </Suspense>
      
    </div>
  );
}

export default App;
