import React, {useEffect, useState} from 'react'
import { TriagePage } from './triagePage'



const TriageComponent = (props) => {
        return (
            <TriagePage
            />
        );
}

export default TriageComponent