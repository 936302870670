import React, { useState, useRef } from "react"
import { Button, Form, FormGroup } from "react-bootstrap"

function SearchBar({onChange}) {
  const [searchValue, setSearchValue] = useState('')
  const searchInputRef = useRef(null)

  const clearFilter = () => {
    setSearchValue('')
    onChange('')
  }

  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        <div className="row">
          <div className="col-md-3 hidden-sm hidden-xs">
            <b>FILTERS &nbsp; &nbsp; </b>
          </div>
          <div className="col-md-9">
            <Form className="col-md-12" inline onSubmit={(e) => e.preventDefault()}>
              <FormGroup className="col-md-5" style={{padding:0}}>
                <input
                  id="filter-input"
                  type="search"
                  value={searchValue}
                  placeholder="First Name, Last Name or ID"
                  onChange={(e) => setSearchValue(e.target.value)}
                  autoComplete={"off"}
                  style={{width:'100%', fontSize:'20px'}}
                  className="form-control"
                  onKeyPress={(e) => e.key === 'Enter' && onChange(searchValue)}
                >
                </input>
              </FormGroup>
              <div 
              style={{marginLeft:'15px',marginRight:'15px', backgroundColor: '#15e1d5', color: 'white', fontSize:'15px'}}
                className='btn col-md-4'
                onClick={(e) => onChange(searchValue)}>
                  SEARCH
              </div>
              <Button 
              style={{marginLeft:'15px',marginRight:'15px', fontSize:'13px'}}
                onClick={clearFilter} 
                className={searchValue.length < 1 ? 'hidden' : ''}>
                  CLEAR FILTER
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchBar